<template>
  <transition name="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__closeIcon" @click="$emit('close')">
              <i class="fas fa-times"></i>
            </div>
          </div>

          <div class="modal__body">
            <CustomForm />
          </div>

          <div class="modal__footer">
            <p class="modal__warningMessage">
              Bu özellik şuanda aktif değil!
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CustomForm from './CustomForm.vue';
export default {
  name: 'Modal',
  components: {
    CustomForm
  }
};
</script>

<style></style>
