<template>
  <div class="companies home__general" v-animate-onscroll="'animated fadeInUp'">
    <h2 class="home__title">
      Hizmet Verdiğimiz Firmalar
    </h2>
    <div class="company__images">
      <div
        class="companyImage__container"
        v-for="(companyImage, index) in companyImages"
        :key="index"
      >
        <img
          :src="require(`../assets/images/${companyImage}`)"
          alt=""
          class="company__image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyImages: [
        'cat.png',
        'cukurova.png',
        'cummins.png',
        'daewoo.png',
        'deutz.png',
        'fiat.png',
        'hako.png',
        'hitachi.png',
        'hyundai.png',
        'iveco.png',
        'jcb.png',
        'komatsu.png',
        'bomag.png',
        'caseconstruction.png',
        'kato.png',
        'dynapac.png',
        'doosan.png',
        'sumitomo.png',
        'mastas.png',
        'newholland.png',
        'hidromek.png',
        'atlascopco.png',
        'furukawa.png',
        'man.png',
        'volvo.png',
        'ammann.png',
        'liebherr.png',
        'linde.png'
      ]
    };
  }
};
</script>

<style></style>
