<template>
  <form class="contact">
    <div class="input-field">
      <input type="text" id="name" required />
      <label for="name">İsim</label>
    </div>
    <div class="input-field">
      <input type="email" id="email" required />
      <label for="email">Email</label>
    </div>
    <div class="input-field">
      <textarea id="message" required></textarea>
      <label for="message">Mesaj</label>
    </div>
    <button class="btn btn__black">
      Gönder
    </button>
  </form>
</template>

<script>
export default {
  name: 'CustomForm'
};
</script>

<style></style>
