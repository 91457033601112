<template>
  <div class="vision home__general" v-animate-onscroll="'animated fadeInUp'">
    <h2 class="home__title">
      Vizyonumuz
    </h2>
    <p>
      Faaliyet gösterilen sektörlerde, uluslararası kalite standartlarında ürün
      ve hizmetleri ile müşteri odaklılığını daima üst seviyede tutarak; saygın,
      güvenilir ve tercih edilen marka olma özelliğini artırarak sürdürmek.
      Ulusal ölçekte kazandığı başarıları uluslararası arenaya da taşımak ve
      büyüme potansiyeli gördüğü iş alanlarının önde gelen isimlerinden olmak.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
