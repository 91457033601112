<template>
  <div class="home">
    <swiper class="swiper" :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <home-hero imageName="banner3">
          <template slot="hero-logo">
            <img
              src="../../assets/svg/logoYellow2.svg"
              alt=""
              class="homeHero__logo logo__yellow"
            />
          </template>

          <template slot="hero-text">
            Emka Radyatör
          </template>

          <button
            slot="hero-button"
            class="btn btn__white"
            @click="showModal = true"
          >
            Bizimle İletişime Geçin
          </button>
        </home-hero>
      </swiper-slide>
      <swiper-slide>
        <home-hero imageName="banner1">
          <template slot="hero-logo">
            <i class="fas fa-wrench homeHero__logo"></i>
          </template>

          <template slot="hero-text">
            30 yıllık tecrübe
          </template>
        </home-hero>
      </swiper-slide>
      <swiper-slide>
        <home-hero imageName="banner2">
          <template slot="hero-logo">
            <i class="fas fa-truck-pickup homeHero__logo"></i>
          </template>

          <template slot="hero-text">
            Kaliteli hizmet ve müşteri anlayışı
          </template>
        </home-hero>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
    <div class="services">
      <h2>Hizmetlerimiz</h2>
      <div class="service__container">
        <Service
          :svgName="'heavy-machinery'"
          :serviceName="'Tüm İş Makinaları Radyatör Hizmeti'"
        />
        <Service
          :svgName="'carRadiator'"
          :serviceName="'Jenaratör Radyatörleri'"
        />
        <Service :svgName="'air'" :serviceName="'Klima Bakımı'" />
        <Service :svgName="'oilCooler'" :serviceName="'Yağ Soğutucu'" />
        <Service :svgName="'intercooler'" :serviceName="'Intercooler'" />
        <Service
          :svgName="'air-compressor'"
          :serviceName="'Hava Kompresör Radyatör'"
        />
      </div>
    </div>
    <Mission />
    <Vision />
    <Companies />
    <Modal v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import Service from '../Service.vue';
import Modal from '../Modal';
import HomeHero from '../HomeHero.vue';
import Mission from '../Mission.vue';
import Vision from '../Vision.vue';
import Companies from '../Companies';
export default {
  name: 'Home',
  components: {
    Service,
    HomeHero,
    Modal,
    Mission,
    Vision,
    Companies
  },
  data() {
    return {
      showModal: false,
      swiperOption: {
        preventClicks: false,
        preventClicksPropagation: false,
        effect: 'fade',
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  metaInfo: {
    title: 'Emka Radyatör',
    meta: [
      {
        name: 'description',
        content:
          'Her türlü iş makinası jeneratör, radyatör, klima, yağ soğutucu, intercooler hava kompresörü; imalat, bakım ve tamiri'
      },
      {
        name: 'keywords',
        content: 'radyatör, makina sanayi, makine, emka radyatör,'
      },
      { name: 'robots', content: 'index,follow' }
    ]
  }
};
</script>

<style></style>
