<template>
  <div class="mission home__general" v-animate-onscroll="'animated fadeInUp'">
    <h2 class="home__title">
      Misyonumuz
    </h2>
    <p>
      İçinde bulunduğumuz sektörde her zaman kaliteli ve güvenilir
      ürünler/hizmetler sunar, müşteri memnuniyetini her şeyin üzerinde tutarız.
      Bilimselliğimiz, uzmanlığımız ve profesyonelliğimizle müşterilerimizin
      ihtiyaç ve beklentilerini karşılamakla kalmaz, daha fazlasını vermek için
      sürekli mükemmeli ararız.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
