<template>
  <div class="service">
    <img :src="svgPath" alt="" />
    <p>{{ serviceName }}</p>
  </div>
</template>

<script>
export default {
  name: 'Service',
  props: {
    svgName: {
      type: String,
      required: true
    },
    serviceName: {
      type: String,
      required: true
    }
  },
  computed: {
    svgPath() {
      return require(`../assets/svg/${this.svgName}.svg`);
    }
  }
};
</script>

<style></style>
