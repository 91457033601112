<template>
  <div class="home__hero">
    <img class="overlayed__image" :src="imagePath" alt="" />
    <!-- <div class="homeHero__logo"> -->
    <slot name="hero-logo"></slot>
    <!-- <img src="../assets/svg/logoYellow.svg" alt="" class="homeHero__logo" /> -->
    <!-- </div> -->

    <div class="homeHero__info">
      <h1 class="company__name">
        <!-- Emka Grup <br />
          Makina Sanayi -->
        <slot name="hero-text"></slot>
      </h1>

      <slot name="hero-button"></slot>
      <!-- <button class="btn btn__white" @click="$emit('open')">
          Bizimle İletişime Geçin
        </button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHero',
  props: {
    imageName: {
      type: String,
      required: true
    }
  },
  computed: {
    imagePath() {
      return require(`../assets/images/${this.imageName}.jpg`);
    }
  }
};
</script>

<style></style>
